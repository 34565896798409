import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";

import config from "../../../config";
// import navigation from '../../../menu-items';
import DEMO from "../../../store/constant";
import Aux from "../../../hoc/_Aux/index";
import FlexBreadcrumb from "./FlexBreadcrumb";

import { CustomersAccess } from "../../../Navigation";

class Breadcrumb extends Component {
  state = {
    main: [],
    item: [],
  };

  componentDidMount() {
    const { user } = this.props.auth;
    let navigation = CustomersAccess;

    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        this.getCollapse(item, index);
      }
      return false;
    });
  }

  UNSAFE_componentWillReceiveProps = () => {
    const { user } = this.props.auth;
    let navigation = CustomersAccess;

    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        this.getCollapse(item);
      }
      return false;
    });
  };

  getCollapse = (item) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          this.getCollapse(collapse);
        } else if (collapse.type && collapse.type === "item") {
          if (document.location.pathname === config.basename + collapse.url) {
            this.setState({ item: collapse, main: item });
          }
        }
        return false;
      });
    }
  };

  render() {
    let main, item;
    let breadcrumb = "";
    let title = "Welcome";
    if (this.state.main && this.state.main.type === "collapse") {
      main = (
        <li className="breadcrumb-item">
          <a href={DEMO.BLANK_LINK}>{this.state.main.title}</a>
        </li>
      );
    }

    if (this.state.item && this.state.item.type === "item") {
      title = this.state.item.title;
      item = (
        <li className="breadcrumb-item">
          <a href={DEMO.BLANK_LINK}>{title}</a>
        </li>
      );

      if (this.state.item.breadcrumbs !== false) {
        breadcrumb = (
          <div className="page-block">
            <div className="page-header-title">
              <h5 className="m-b-10">{title}</h5>
            </div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="feather icon-home" />
                </Link>
              </li>
              {main}
              {item}
            </ul>
          </div>
        );
      }
    }

    document.title = title + " | EmergeRemit";

    let { overrideMenu } = this.props;

    //this simply replaces the data
    if (overrideMenu) {
      let { pageTitle, documentTitle, crumbData } = this.props;

      breadcrumb = (
        <FlexBreadcrumb
          overrideMenu={overrideMenu}
          pageTitle={pageTitle}
          documentTitle={documentTitle}
          crumbData={crumbData}
        />
      );
    }

    return <Aux>{breadcrumb}</Aux>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps)(Breadcrumb));
