import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// users access
const TrustPayment = React.lazy(() => import("./Containers/Settings/Index"));

// Remittance routes
const RemittanceDashboard = React.lazy(() =>
  import("./Containers/Remittance/Dashboard/Dashboard")
);

const Tenants = React.lazy(() => import("./Containers/Remittance/Tenants"));
const TenantDetails = React.lazy(() =>
  import("./Containers/Remittance/Tenants/TenantDetails")
);

const TenantAccounts = React.lazy(() =>
  import("./Containers/Remittance/Accounts")
);
const TenantAccountDetails = React.lazy(() =>
  import("./Containers/Remittance/Accounts/AccountDetails")
);

const Payouts = React.lazy(() => import("./Containers/Remittance/Payouts"));
const PayoutDetails = React.lazy(() =>
  import("./Containers/Remittance/Payouts/PayoutDetails")
);

//Transactions
const SendMoney = React.lazy(() =>
  import("./Containers/Remittance/Transactions/SendMoney")
);

const ClientTransactions = React.lazy(() =>
  import("./Containers/Remittance/Transactions")
);

// Currency
const Currency = React.lazy(() =>
  import("./Containers/Remittance/Currencies/Currency")
);
const CurrencyDetails = React.lazy(() =>
  import("./Containers/Remittance/Currencies/CurrencyDetails")
);

const Fees = React.lazy(() => import("./Containers/Remittance/Fees/Fees"));
const FeeDetails = React.lazy(() =>
  import("./Containers/Remittance/Fees/FeeDetails")
);

const Forex = React.lazy(() => import("./Containers/Forex/Forex"));
const ForexDetails = React.lazy(() =>
  import("./Containers/Forex/ForexDetails")
);

//Customers
const SendingCustomers = React.lazy(() =>
  import("./Containers/Customers/Customers")
);
const SendingCustomerKycDetails = React.lazy(() =>
  import("./Containers/Customers/ViewCustomerKycDetails")
);

// Purpose
const Purposes = React.lazy(() =>
  import("./Containers/Remittance/Purposes/Purposes")
);
const PurposeDetails = React.lazy(() =>
  import("./Containers/Remittance/Purposes/PurposeDetails")
);

const Logs = React.lazy(() => import("./Containers/Remittance/Logs"));

const Roles = React.lazy(() => import('./Containers/Roles/Roles'));
const RolesDetails = React.lazy(() => import('./Containers/Roles/ViewRoleDetails'));

//Users
const AccountUsers = React.lazy(() => import('./Containers/Remittance/Accounts/Users/AccountUsers'));
const AccountUsersDetails = React.lazy(() => import('./Containers/Remittance/Accounts/Users/ViewAccountUserDetails'));

//KYC
const AccountUserKyc = React.lazy(() => import('./Containers/Remittance/Accounts/Kyc/Kyc'));
const AccountUserKycDetails = React.lazy(() => import('./Containers/Remittance/Accounts/Kyc/ViewKycDetails'));


const routes = [
  // remittance admin
  {
    path: "/client/dashboard",
    permissionId: 'dashboard-page',
    exact: true,
    name: "Dashboard",
    component: RemittanceDashboard
  },
  {
    path: "/tenants",
    permissionId: 'menu-client-tenants',
    exact: true,
    name: "Tenants",
    component: Tenants
  },
  {
    path: "/tenants/:id",
    permissionId: 'menu-client-tenants',
    exact: true,
    name: "View Tenant",
    component: TenantDetails
  },
  {
    path: "/tenant-accounts",
    permissionId: 'menu-client-accounts',
    exact: true,
    name: "Accounts",
    component: TenantAccounts
  },
  {
    path: "/tenant-accounts/:id",
    permissionId: 'menu-client-accounts',
    exact: true,
    name: "View Account",
    component: TenantAccountDetails
  },
  {
    path: "/payouts",
    permissionId: 'menu-client-payouts',
    exact: true,
    name: "Payouts",
    component: Payouts
  },
  {
    path: "/payouts/:id",
    permissionId: 'menu-client-payouts',
    exact: true,
    name: "View Payout",
    component: PayoutDetails
  },
  {
    path: "/logs",
    permissionId: 'menu-client-logs',
    exact: true,
    name: "Logs",
    component: Logs
  },
  {
    path: "/sending/customers",
    permissionId: "menu-customer-page",
    exact: true,
    name: "Customers",
    component: SendingCustomers
  },
  {
    path: "/sending/customers/add-user",
    permissionId: "menu-customer-page",
    exact: true,
    name: "Customer Onboarding",
    component: SendingCustomerKycDetails
  },
  {
    path: "/sending/customers/:id",
    permissionId: "menu-customer-page",
    exact: true,
    name: "Customer KYC Details",
    component: SendingCustomerKycDetails
  },
  //send money
  {
    path: "/client/transactions/sendmoney",
    permissionId: "menu-sendmoney-page",
    exact: true,
    name: "Send Money",
    component: SendMoney
  },
  {
    path: "/client/transactions/sendmoney/:customerId",
    permissionId: "menu-sendmoney-page",
    exact: true,
    name: "Send Money",
    component: SendMoney
  },
  {
    path: "/client/transactions",
    permissionId: "menu-transactions-page",
    exact: true,
    name: "Transactions",
    component: ClientTransactions
  },
  // settings - roles
  { path: '/settings/roles', permissionId: 'menu-level-roles', exact: true, name: 'Roles', component: Roles },
  { path: '/settings/roles/add-role', permissionId: 'menu-level-roles', exact: true, name: 'View Role', component: RolesDetails },
  { path: '/settings/roles/:id', permissionId: 'menu-level-roles', exact: true, name: 'View Role', component: RolesDetails },
  // accounts - users
  { path: '/settings/users', permissionId: 'menu-users-main', exact: true, name: 'Account Users', component: AccountUsers },
  { path: '/settings/users/add-account-user', permissionId: 'menu-users-main', exact: true, name: 'Add account user', component: AccountUsersDetails },
  { path: '/settings/users/:id', permissionId: 'menu-users-main', exact: true, name: 'View account user', component: AccountUsersDetails },
  // accounts - kyc
  { path: '/settings/kyc', permissionId: 'menu-users-kyc', exact: true, name: 'Users', component: AccountUserKyc },
  { path: '/settings/kyc/:id', permissionId: 'menu-users-kyc', exact: true, name: 'View Kyc', component: AccountUserKycDetails },
  // setting - currency
  {
    path: "/settings/currency",
    permissionId: "menu-level-currency",
    exact: true,
    name: "Currency",
    component: Currency
  },
  {
    path: "/settings/currency/:id",
    permissionId: "menu-level-currency",
    exact: true,
    name: "View Currency",
    component: CurrencyDetails
  },
  {
    path: "/settings/fees",
    permissionId: "menu-level-fees",
    exact: true,
    name: "Fees",
    component: Fees
  },
  {
    path: "/settings/fees/:id",
    permissionId: "menu-level-fees",
    exact: true,
    name: "View Fee",
    component: FeeDetails
  },
  // setting - forex
  {
    path: "/forex",
    permissionId: "menu-level-forex",
    exact: true,
    name: "Forex",
    component: Forex
  },
  {
    path: "/forex/add-forex",
    permissionId: "menu-level-forex",
    exact: true,
    name: "View Forex",
    component: ForexDetails
  },
  {
    path: "/forex/:baseCurrencyId/:quoteCurrencyId",
    permissionId: "menu-level-forex",
    exact: true,
    name: "View Forex",
    component: ForexDetails
  },
  {
    path: "/settings/purposes",
    permissionId: "menu-level-purposes",
    exact: true,
    name: "Purposes",
    component: Purposes
  },
  {
    path: "/settings/purposes/:id",
    permissionId: "menu-level-purposes",
    exact: true,
    name: "View Purpose",
    component: PurposeDetails
  },
  {
    path: "/settings/payments",
    permissionId: "menu-level-payments",
    exact: true,
    name: "Test Payment Page",
    component: TrustPayment
  },
];

export default routes;
