
import http from "../../api/http";
import Config from "../../../config";
import { showNotification } from "../notification";
import { setReduxDropdownActionLength } from '../redux_actions';
import _ from 'lodash';

const strGeneralError =
    "There was a problem in connecting to the server. Please check your internet and try again.";


export function ClearSelectedAccount() {
    return (dispatch) => {
        dispatch({ type: "CLEAR_SELECTED_ACCOUNTS" });
    };
}

export function dismissAccountError() {
    return (dispatch) => {
        dispatch({ type: "DISMISS_ACCOUNT_ERROR" });
    };
}

export function SetAccountLoading(loadingMsg) {
    return (dispatch) => {
        dispatch({ type: "ACCOUNT_LOADING", loadingMsg: loadingMsg });
    };
}

export function GetAccount(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_A_ACCOUNT", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}

export function GetAccountList(state, instance, customerOnly) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_ACCOUNTS_URI;

    let params = {};

    if (customerOnly) {
        params = { role: 'customer' }
    }

    const request = http.get(apiBaseUrl, {
        params,
    });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNTS", meta: data.meta, payload: data });
                dispatch(setReduxDropdownActionLength(data && data.length))
            },
            (error) => {
                if (
                    String(error) ===
                    "TypeError: Cannot read property 'parseError' of undefined"
                ) {
                    dispatch({ type: "ACCOUNT_LOADING_STOP" });
                } else {
                    dispatch(showNotification({ type: "error", message: error.messages }));
                    dispatch({ type: "ERROR_ACCOUNTS", errorMsg: strGeneralError });
                }
            }
        );
    };
}

export function CreateAccount(objParams) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_ACCOUNTS_URI;

    let params = {
        ...objParams,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating account information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_CREATED', payload: data })
            dispatch(showNotification({ type: "success", message: "Account has successfully been created!" }));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages, payload: params });
            });
    }
}

export function UpdateAccount(objParams, accountId, changePassword) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_KYC_URI.concat(`${accountId}`);


    let params = {
        data: {
            type: "PasswordChange",
            attributes: {
                ...objParams
            }
        }
    };

    const request = http.patch(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Updating account information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'USER_UPDATED', payload: data })
            dispatch(showNotification({ type: "success", message: "Account has successfully been updated!" }));

            // logout user
            if (changePassword) {
                dispatch({ type: 'LOGOUT' })
            }

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages })
            });
    }
}

export function ReplaceAccounts(objParams) { }

// KYC
export function UploadRequiredDocuments(objData, kycInformation, customerId) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_MULTI_UPLOAD_URI;
    var formData = new FormData();

    let files = { ...objData };

    if (Array.isArray(objData)) {

        for (var key in objData) {
            formData.append("files", objData[key]);
        }

        //build form header
        http.interceptors.request.use(config => {
            config.headers = { "Content-Type": "multipart/form-data" };
            return config;
        });

        const request = http.post(apiBaseUrl, formData);

        return dispatch => {
            request.then(
                ({ data }) => {

                    let documents = [];
                    _.map(data, (f) => {

                        let type = _.find(files, (file) => { return file.name === f.originalname }) || "";

                        documents.push({
                            "filename": f.filename,
                            "documentType": type.fileType
                        })
                    })

                    if (!_.isEmpty(documents))
                        kycInformation.documents = documents;

                    if (kycInformation.status === "draft") {
                        dispatch(StartKYC(kycInformation, customerId));
                    } else {
                        dispatch(UpdateKYC(kycInformation, customerId));
                    }

                },
                error => {
                    console.log("Error Request", error);
                    dispatch({ type: "ERROR_SAVINGS", errorMsg: error.messages });
                    dispatch(showNotification({ type: "error", message: error.messages }));
                }
            );
        };
    }
}

export function StartKYC(kycInformation, customerId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_URI.concat(`${customerId}/kyc`);

    let params = {
        ...kycInformation,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating kyc information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'KCY_CREATED', payload: data })
            dispatch(showNotification({ type: "success", message: "KYC information has successfully been created!" }));

        },
            (error) => {
                console.log("MAKER", error);

                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages, payload: params });
                dispatch(showNotification({ type: "error", message: error.messages }));

            });
    }

}

export function UpdateKYC(kycInformation, customerId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_URI.concat(`${customerId}/kyc`);

    let params = {
        ...kycInformation,
    }
    const request = http.put(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Updating kyc status information..."));

        request.then(({
            data
        }) => {

            console.log('KCY_UPDATED', data);

            dispatch({ type: 'KCY_UPDATED', payload: params })
            dispatch(showNotification({ type: "success", message: "KYC information has successfully been updated!" }));
        },
            (error) => {

                console.log(error);

                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages, payload: params });
                dispatch(showNotification({ type: "error", message: error.messages }));

            });
    }

}

// cards
export function GetCardList(state, instance, customerId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_ACCOUNT_CARDS;

    let params = {};
    const request = http.get(apiBaseUrl, {
        params,
    });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_CARDS", meta: data.meta, payload: data });
                dispatch(setReduxDropdownActionLength(data && data.length))
            },
            (error) => {
                if (
                    String(error) ===
                    "TypeError: Cannot read property 'parseError' of undefined"
                ) {
                    dispatch({ type: "ACCOUNT_LOADING_STOP" });
                } else {
                    dispatch(showNotification({ type: "error", message: error.messages }));
                    dispatch({ type: "ERROR_ACCOUNTS", errorMsg: strGeneralError });
                }
            }
        );
    };
}

export function CreateCards(objParams) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_ACCOUNT_CARDS;

    let params = {
        ...objParams,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating cards information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_CARD_CREATED' })
            dispatch(showNotification({ type: "success", message: "Account cards has successfully been created!" }));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages, payload: params });
            });
    }
}



export function GetAccountRewards(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNT_REWARDS", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}


export function GetAccountVouchers(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNT_VOUCHERS", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}
