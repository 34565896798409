export default {
  items: [
    {
      id: "client-remittance",
      title: "Client Remittance",
      type: "group",
      icon: "icon-support",
      isAdminNav: false,
      children: [
        {
          id: "dashboard-page",
          title: "Dashboard",
          type: "item",
          url: "/client/dashboard",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "customer-page",
          title: "Profile",
          type: "item",
          url: "/sending/customers/",
          icon: "feather icon-user-check"
        },
        {
          id: "transaction-page",
          title: "Transactions",
          type: "item",
          url: "/client/transactions",
          classes: "nav-item",
          icon: "feather icon-list"
        },
      ]
    }
  ]
};
